import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const CommonPage = ({ content,  anchorItemClick, setMenuDataLoaded }) => {
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const companyName = 'Offensive Logic LLC'; // Replace with your actual company name
  var pathWithoutSlash = currentPath.substring(1);
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiBaseUrl = process.env.REACT_APP_BASE_API_URL;


  const targetElementRef = useRef(null);

  const smoothScrollTo = (element, duration, marginTop = 0) => {
    const targetPosition = element.offsetTop - marginTop;
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    let startTime = null;

    const animation = (currentTime) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);
      window.scrollTo(0, startPosition + distance * easeInOutQuart(progress));

      if (timeElapsed < duration) {
        requestAnimationFrame(animation);
      }
    };

    const easeInOutQuart = (t) => (t < 0.5 ? 8 * t ** 4 : 1 - Math.pow(-2 * t + 2, 4) / 2);

    requestAnimationFrame(animation);
  };

  const scrollToElement = () => {
    if (window.location.pathname === '/about-us/') {
      const targetElement = document.getElementById('gQIqACV');
      if (targetElement) {
        const marginTop = 150; // Set your desired margin-top value here
        smoothScrollTo(targetElement, 800, marginTop);
      }
    }
  };

  useLayoutEffect(() => {
    scrollToElement();
  }, [currentPath, targetElementRef.current, content]);
  



  useEffect(() => {
    const handleAnchorClick = (event) => {
      let target = event.target;
      while (target && target.tagName !== 'A') {
        target = target.parentNode;
      }
      if (target && target.tagName === 'A') {
        event.preventDefault();
        const href = target.getAttribute('href');
        if (href === '/page/services/') {
          navigate('/services');
          anchorItemClick(13);
        } else if (href === '/page/contact-us/' || href === 'page/contact-us/') {
          navigate('/contact-us');
          anchorItemClick(3);
        } else if (href === 'page/about-us/?id=gQIqACV') {
          setMenuDataLoaded(true);
          navigate('/about-us/?id=gQIqACV');
          anchorItemClick(2);
        } else if (href === 'page/about-us/') {
          navigate('/about-us');
          anchorItemClick(2);
        } else if (href === 'page/web-application/' || href === '/page/web-application/') {
          navigate('/web-application');
          anchorItemClick(30);
        } else if (href === 'page/network-penetration/' || href === '/page/network-penetration/') {
          navigate('/network-penetration');
          anchorItemClick(29);
        } else if (href === 'page/wireless-penetration/' || href === '/page/wireless-penetration/') {
          navigate('/wireless-penetration');
          anchorItemClick(31);
        } else if (href === 'page/social-engineering-phishing-physical/' || href === '/page/social-engineering-phishing-physical/') {
          anchorItemClick(34);
          navigate('/social-engineering-phishing-physical');
        } else if (href === 'page/mobile-application-penetration-testing/' || href === '/page/mobile-application-penetration-testing/') {
          anchorItemClick(33);
          navigate('/mobile-application-penetration-testing');
        } else if (href === 'page/red-teaming/' || href === '/page/red-teaming/') {
          anchorItemClick(27);
          navigate('/red-teaming');
        } else if (href.startsWith('tel:')) {
          const telephoneNumber = href.substring(4); 
          console.log('Telephone:', telephoneNumber);
          if (href && href.startsWith('tel:')) {
            event.preventDefault();
            window.location.href = href;
          }
        } else if (href.startsWith('mailto:')) {
          const email = href.substring(7);
          if (href && href.startsWith('mailto:')) {
            event.preventDefault();
            window.open(href, '_blank');
          }
        }
      }
    };
    const commonPageContainer = document.getElementById('commonPage');
    if (commonPageContainer) {
      commonPageContainer.addEventListener('click', handleAnchorClick);
    }
    return () => {
      if (commonPageContainer) {
        commonPageContainer.removeEventListener('click', handleAnchorClick);
      }
    };
  }, []);


  useEffect(() => {
    const textareaInput = document.querySelector('input.description');
    const textareaElement = document.createElement('textarea');
    textareaElement.placeholder = '';
    textareaElement.name = 'notes';
    textareaElement.classList.add('description'); 

    if (textareaInput) {
      textareaInput.parentNode.replaceChild(textareaElement, textareaInput);
    }

    const handleFieldEvent = (event) => {
      const parentField = event.target.closest('.field');
      if (parentField) {
        if (event.type === 'focus') {
          parentField.classList.add('input_focus');
        } else if (event.type === 'blur') {
          if (!event.target.value.trim()) {
            parentField.classList.remove('input_focus');
          }
        }
      }
    };

    textareaElement.addEventListener('focus', handleFieldEvent, true);
    textareaElement.addEventListener('blur', handleFieldEvent, true);

    const form = document.getElementById('wojo_form');
    const allFields = form ? form.querySelectorAll('.field') : [];

    allFields.forEach((field) => {
      field.addEventListener('focus', handleFieldEvent);
      field.addEventListener('blur', handleFieldEvent);
    });

    return () => {
      allFields.forEach((field) => {
        field.removeEventListener('focus', handleFieldEvent);
        field.removeEventListener('blur', handleFieldEvent);
      });

      if (textareaInput && textareaElement) {
        textareaElement.parentNode.replaceChild(textareaInput, textareaElement);
      }
    };
  }, [content]);

  const [fieldErrors, setFieldErrors] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    notes: ''
  });
  const [contactFormData, setContactFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    notes: ''
  });

  // const generateErrorElement = (fieldName, errorMessage) => {
  //   const errorElement = document.createElement('div');
  //   errorElement.className = 'error-message';
  //   errorElement.textContent = errorMessage;
  //   errorElement.dataset.fieldName = fieldName; // Add a data attribute to identify the field

  //   const parentField = document.querySelector(`[name="${fieldName}"]`).closest('.field');
  //   parentField.appendChild(errorElement);
  // };

  const generateErrorElement = (fieldName, errorMessage) => {
    const errorElement = document.createElement('div');
    errorElement.className = 'error-message';
    errorElement.textContent = errorMessage;
    errorElement.dataset.fieldName = fieldName; // Add a data attribute to identify the field
  
    const parentField = document.querySelector(`[name="${fieldName}"]`).closest('.field');
    parentField.appendChild(errorElement);
  
    // Add 'empty' class if the field has an error
    const input = parentField.querySelector('input, textarea');
    if (input) {
      input.classList.add('empty');
    }else{

    }
  };

  // const removeErrorElement = (fieldName) => {
  //   const errorElement = document.querySelector(`.error-message[data-field-name="${fieldName}"]`);
  //   if (errorElement) {
  //     errorElement.remove();
  //   }
  // };

  const removeErrorElement = (fieldName) => {
    const errorElement = document.querySelector(`.error-message[data-field-name="${fieldName}"]`);
    if (errorElement) {
      const errorMessage = errorElement.textContent.trim();
  
      // Check if the error message is "Cannot Be Blank"
      if (errorMessage === `${fieldName} Field cannot be blank`) {
        const parentField = errorElement.closest('.field');
        const input = parentField.querySelector('input, textarea');
        if (input) {
          input.classList.remove('empty');
        }
      }
  
      errorElement.remove();
    }
  };
  

  

  const validateForm = (form) => {
    const validationErrors = {};

    // Validate your form fields and set errors
    const inputFields = form.querySelectorAll('input[type="text"], textarea[name="notes"], input[type="email"], input[type="tel"]');
    inputFields.forEach((input) => {
      const fieldName = input.name;

      // Validation for non-empty fields
      if (!input.value.trim()) {
        validationErrors[fieldName] = `${fieldName} Field cannot be blank`;
        generateErrorElement(fieldName, `${fieldName} Field cannot be blank`);
      } else {
        removeErrorElement(fieldName); // Remove error if the field is not empty
      }

      // Email validation
      if (fieldName === 'email' && input.value.trim()) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(input.value.trim())) {
          validationErrors[fieldName] = 'Invalid email format';
          generateErrorElement(fieldName, 'Invalid email format');
        }
      }

      // Phone number validation
      if (fieldName === 'phone' && input.value.trim()) {
        const phonePattern = /^\d{8,}$/; // Adjust the pattern based on your requirements
        if (!phonePattern.test(input.value.trim())) {
          validationErrors[fieldName] = 'Invalid phone number format';
          generateErrorElement(fieldName, 'Invalid phone number format');
        }
      }
    });

    return validationErrors;
  };


  const removeErrorElements = () => {
    const errorElements = document.querySelectorAll('.error-message');
    errorElements.forEach((errorElement) => {
      errorElement.remove();
    });
  };

  useEffect(() => {
    const handleInput = (event) => {
      const fieldName = event.target.name;
      // const inputValue = event.target.value.trim();

      // if (inputValue) {
      //   removeErrorElement(fieldName);
      // }
      removeErrorElement(fieldName)
    };

    const inputFields = document.querySelectorAll('input[type="text"], textarea[name="notes"]');
    inputFields.forEach((input) => {
      input.addEventListener('input', handleInput);
    });

    return () => {
      inputFields.forEach((input) => {
        input.removeEventListener('input', handleInput);
      });
    };
  }, [content]);


  const message = (form,message) =>{
    const successMessageDiv = document.createElement('div');
    successMessageDiv.className = 'success-message-mail';
    successMessageDiv.textContent = message;
    const formContainer = form.closest('#wojo_form');
    formContainer.appendChild(successMessageDiv);
    // setTimeout(() => {
    //   successMessageDiv.remove();
    // }, 5000);
  }

  const handleSubmitClick = async (form) => {
    try {
      removeErrorElements(); // Clear existing error messages

      const formData = new FormData(form);
      const validationErrors = validateForm(form);

      if (Object.keys(validationErrors).length > 0) {
        setFieldErrors(validationErrors);
        return;
      }
      localStorage.removeItem('contactFormData');

      const updatedFormData = {};
      formData.forEach((value, key) => {
        updatedFormData[key] = value;
      });
      setContactFormData(updatedFormData);

      if (updatedFormData) {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            model: 'sendMail',
          firstname: updatedFormData.firstName,
          lastname: updatedFormData.lastName,
          email: updatedFormData.email,
          phone: updatedFormData.phone,
          notes: updatedFormData.notes

          }),
        });
        const data = await response.json();
        if (data.returnCode === 1) {
          console.log('Thank you for your message. It has been sent:', data.message);
          form.reset();
          message(form,'Thank you for your message. It has been sent');
        } else {
          console.error('Error submitting Mail:', data.message);
          message(form,'Error submitting Mail');
        }
      }
    } catch (error) {
      console.error('An error occurred while submitting the Mail:', error);
      message(form,'An error occurred while submitting the Mail');
    }
  };

  useEffect(() => {
    const textareaInput = document.querySelector('input.description');
    const textareaElement = document.createElement('textarea');
    textareaElement.placeholder = '';
    textareaElement.name = 'notes'; 
    textareaElement.classList.add('description'); 

    if (textareaInput) {
      textareaInput.parentNode.replaceChild(textareaElement, textareaInput);
    }
 
    const handleButtonClick = (event) => {
      const target = event.target;
      const isFormButton =
        (target.tagName === 'BUTTON' || target.tagName === 'INPUT') &&
        (target.form || target.closest('form')) !== null &&
        (target.dataset.action === 'processContact');
    
      if (isFormButton) {
        const form = target.form;
        if (form) {
          target.disabled = true;
          handleSubmitClick(form).finally(() => {
            target.disabled = false;
          });
        }
      }
    };

    const handleFieldEvent = (event) => {
      const parentField = event.target.closest('.field');
      if (parentField) {
        if (event.type === 'focus') {
          parentField.classList.add('input_focus');
        } else if (event.type === 'blur') {
          if (!event.target.value.trim()) {
            parentField.classList.remove('input_focus');
          }
        }
      }
    };

    const formContainer = document.getElementById('commonPage');
    if (formContainer) {
      formContainer.addEventListener('click', handleButtonClick);
      formContainer.addEventListener('focus', handleFieldEvent, true);
      formContainer.addEventListener('blur', handleFieldEvent, true);

      return () => {
        formContainer.removeEventListener('click', handleButtonClick);
        formContainer.removeEventListener('focus', handleFieldEvent, true);
        formContainer.removeEventListener('blur', handleFieldEvent, true);
        if (textareaInput && textareaElement) {
          textareaElement.parentNode.replaceChild(textareaInput, textareaElement);
        }
      };
    }
  }, []);

  if(pathWithoutSlash === ''){
    pathWithoutSlash='Home';
  }

  
  setTimeout(() => {
    const element = targetElementRef.current;
    element.classList.remove("loader-white-screen");
}, 1000);

  return <div>
    <Helmet>
      <title>{`${pathWithoutSlash.charAt(0).toUpperCase() + pathWithoutSlash.slice(1)} ${'-'} ${companyName} `}</title>
      {/* <link rel="icon" href='/assets/fevicon-logo.png" type="image/x-icon" /> */}
      <link rel="icon" href={`${apiBaseUrl}/assets/fevicon-logo.png`} type="image/x-icon" />
    </Helmet>
    <div
      id="commonPage"
      className='loader-white-screen'
      dangerouslySetInnerHTML={{ __html: content }}
      ref={(el) => (targetElementRef.current = el)}>
    </div>
  </div>
};

export default CommonPage;
