import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Carousel = ({ currentIndex, nextSlide, prevSlide, sliderData, imagesToShow }) => {
  const [visibleSlides, setVisibleSlides] = useState([]);

  useEffect(() => {
    const startIndex = Math.max(0, currentIndex);
    const endIndex = Math.min(sliderData.length - 1, startIndex + getVisibleSlides() - 1);

    let newVisibleSlides = sliderData.slice(startIndex, endIndex + 1);

    // Check if additional images are needed for the looping effect
    const remainingImages = imagesToShow - newVisibleSlides.length;
    if (remainingImages > 0) {
      newVisibleSlides = newVisibleSlides.concat(sliderData.slice(0, remainingImages));
    }

    setVisibleSlides(newVisibleSlides);
  }, [currentIndex, sliderData, imagesToShow]);

  const getVisibleSlides = () => {
    return window.innerWidth <= 768 ? 3 : imagesToShow;
  };

  const slideWidth = `calc(100% / ${getVisibleSlides()})`;
  const marginRight = `calc((10% / ${getVisibleSlides()}) - 1px)`; // 1px for avoiding decimal rounding issues

  return (
    <div className='carousel-container'>

      <a href='/#'>
        <div
          className='carousel-data'
          style={{
            display: 'flex',
            overflow: 'hidden',
            width: '100%',
            margin: '0 auto', // Center the slides
            transition: 'transform 0.5s ease',
          }}
        >
          {visibleSlides.map((sliderDataItem, index) => (
            <div key={index} style={{ flex: `0 0 ${slideWidth}` }}>
              <div dangerouslySetInnerHTML={{ __html: sliderDataItem }}></div>
            </div>
          ))}
        </div>
      </a>

      <div className="carousel-buttons">
        <button className="button carousel-previous" onClick={() => prevSlide()}>
          Carousel Previous
        </button>
        <button className="button carousel-next" onClick={() => nextSlide()}>
          Carousel Next
        </button>
      </div>
    </div>
  );
};

Carousel.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  nextSlide: PropTypes.func.isRequired,
  prevSlide: PropTypes.func.isRequired,
  sliderData: PropTypes.array.isRequired,
  imagesToShow: PropTypes.number.isRequired,
};

export default Carousel;
