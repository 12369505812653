// Address.js
import React from 'react';

const Address = ({ data }) => {
  const handlePhoneClick = () => {
    window.location.href = 'tel:(210) 236-2466';
  }

  const handleEmailClick = () => {
    window.location.href = `mailto:${data?.site_email}`;
  }

  return (
    <div className="row big gutters contactDetail">
      <div className="columns aboutDetail">
        <span>{data?.company} is proud to be HUB and SMVOBE certified company and one of the leaders in cyber security.</span>
      </div>
      <div className="columns addressDetail">
        <span>Offensive Logic LLC</span>
        <div>17305 I-35 Suite 103, Schertz, TX 78154.</div>
      </div>
      <div className="columns phoneDetail">
        <span>Phone</span>
        <div onClick={handlePhoneClick}>(210) 236-2466</div>
      </div>
      <div className="columns emailDetail">
        <span>Email</span>
        {/* <div onClick={handleEmailClick}>{data?.site_email}</div> */}
        <div onClick={handleEmailClick}>{'info@offensive-logic.com'}</div>
      </div>
    </div>
  );
};

export default Address;
