// Navigation.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


const MenuItem = ({ item, onMenuItemClick, activeMenuItemPath, setActiveSubMenuPath }) => {
  const handleToggleClick = () => {
    const body = document.querySelector('.menu-sub-item-data');
    body.classList.toggle('toggle-menu');
  };

  const handleClick = () => {
    onMenuItemClick && onMenuItemClick(item);
    const body = document.querySelector('html');
    // Remove the class if it exists
    if (body.classList.contains('menu-hamburger-class')) {
      body.classList.remove('menu-hamburger-class');
    }
  };

  return (
    <li className={item.submenu ? 'menu-sub-item-data' : 'menu-item-data'}>
      {item.submenu && (
        <span className='toggle' onClick={handleToggleClick}></span>
      )}
      <Link
        to={item.path}
        onClick={handleClick}
        className={isActiveMenuItem(item, activeMenuItemPath, setActiveSubMenuPath) ? 'active-menu-item' : ''}
      >
        {item.label}
      </Link>
      {item.submenu && <SubMenu items={item.submenu} onMenuItemClick={onMenuItemClick} activeMenuItemPath={activeMenuItemPath} setActiveSubMenuPath={setActiveSubMenuPath} />}
    </li>
  );
};




const isActiveMenuItem = (menuItem, activePath, setActiveSubMenuPath) => {
  if (menuItem.path === activePath) {
    return true;
  }

  if (menuItem.submenu) {
    // Recursive check for submenu items
    setActiveSubMenuPath(menuItem.submenu.some((subItem) => isActiveMenuItem(subItem, activePath, setActiveSubMenuPath)));
    return menuItem.submenu.some((subItem) => isActiveMenuItem(subItem, activePath, setActiveSubMenuPath));
  }

  return false;
};



const SubMenu = ({ items, onMenuItemClick, activeMenuItemPath, setActiveSubMenuPath }) => (
  <ul className='nav-item'>
    {items.map((subItem) => (
      <MenuItem
        key={subItem.id}
        item={subItem}
        onMenuItemClick={onMenuItemClick}
        activeMenuItemPath={activeMenuItemPath}
        setActiveSubMenuPath={setActiveSubMenuPath}
      />
    ))}
  </ul>
);

const Navigation = ({ data, onMenuItemClick, onMatchPath, menu, activeMenuItemPath, setActiveSubMenuPath }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  // const [menu, setMenu] = useState(null);

  // Humberger Button
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [menuOpen, setMenuOpen] = useState(false);

  // Hamburger Button
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMenuClick = () => {
    // setMenuOpen(!menuOpen);
    const body = document.querySelector('html');
    body.classList.toggle('menu-hamburger-class', !menuOpen);
  };

  const removeClass = () => {
    // Remove the class when the "Close" button is clicked
    const body = document.querySelector('html');
    body.classList.remove('menu-hamburger-class');
  };



  // Scroll effect useEffect
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const isHeaderScrolled = scrollY > 0; // You can adjust the scroll threshold

      setIsScrolled(isHeaderScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures that it runs once after the initial render

  if (!menu) {
    return <div>Loading...</div>;
  }

  const onLogoClick = () => {
    onMatchPath('/home');
  }

  const currentPath = window.location.pathname;

  //   <header className='header ' >
  return (
    <div>


      <header className={`header ${isScrolled ? 'sticky' : ''}`} >
        <nav className="wojo-grid">
          <div className='header-container'>


            {/* <div className="logo" onClick={onLogoClick}>
              {currentPath === '' || currentPath === '/home' &&
                <a href={'/home'} >
                  <img src={data?.logo} alt="Logo" />
                </a>
              }else {
                <Link to={'/home'} >
                  <img src={data?.logo} alt="Logo" />
                </Link>
              }
            </div> */}


            <div className="logo" onClick={onLogoClick}>
              {currentPath === '' || currentPath === '/home' ? (
                <a href="/home">
                  <img src={data?.logo} alt="Logo" />
                </a>
              ) : (
                <Link to="/home">
                  <img src={data?.logo} alt="Logo" />
                </Link>
              )}
            </div>

            {/* {isMobile && ( */}
            <span className='humberger-menu' onClick={handleMenuClick}> HumbergerButton</span>
            {/* )} */}
            <div className='ul-wrap'>
              {/* {isMobile && ( */}
              <span className='close-btn' onClick={removeClass}> close</span>
              {/* )} */}
              <ul className='menu-item'>
                {menu.map((item) => (
                  <MenuItem key={item.id} item={item} onMenuItemClick={onMenuItemClick} activeMenuItemPath={activeMenuItemPath} setActiveSubMenuPath={setActiveSubMenuPath} />
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navigation;
