import React from 'react';
import Navigation from '../Header/Navigation';
import Address from '../Address/Address';
import CopyRights from '../CopyRights/CopyRights';

 
const Footer = ({ data ,onMenuItemClick,onMatchPath,menu,activeMenuItemPath,setActiveSubMenuPath}) => {
  return (
    <footer>
      <div className='bottom-header'>
        <Navigation data={data} onMenuItemClick={onMenuItemClick} onMatchPath={onMatchPath} menu={menu} activeMenuItemPath={activeMenuItemPath} setActiveSubMenuPath={setActiveSubMenuPath}  />
      </div>
      <div className='wojo-grid'>
        <Address data={data} />
      </div>
      <CopyRights onMatchPath={onMatchPath}/>
    </footer>
  );
};

export default Footer;