import React from 'react';
import { Link } from 'react-router-dom';

const Banner = ({ setActiveSubMenuPath,response, onMatchPath, activeSubMenuPath, responseData, selectedBlog }) => {
    // console.log('Banner', 'response : ', response);
    // console.log('Banner', 'slug_en : ', response.slug_en);
    // console.log("Selected Blog", selectedBlog);
    const blogToRetrieve = responseData?.find(blog => blog.id === selectedBlog);

    // console.log("activeSubMenuPath  :  ", activeSubMenuPath);
    const onBreadcrumbClick = (url) => {

      
        onMatchPath(url);
    };
    return (
        <div className='banner'>
            <div className='banner-image'>
                <img src={response.banner_en} alt='Banner' />
            </div>
            <div class='wojo-grid'>
                {
                     selectedBlog > 0 && (<div className='title-name'>{'Blog Detail'}</div>)
                }
                {
                     selectedBlog === 0 && (<div className='title-name'>{response.title_en}</div>)
                }
                
            </div>
            <div class='wojo-grid wojo-breadcrumb'>
                <div class='row gutters container'>
                    <div class='wojo breadcrumb'>
                        <div className='home-click' onClick={() => onBreadcrumbClick('/home')}>
                        <Link to='/home'> {'Home'}</Link>
                        </div>
                        <span class='divider'>/</span>
                        {activeSubMenuPath &&  (
                            <div className='click' onClick={() => onBreadcrumbClick('/services')}>
                                {setActiveSubMenuPath(false)}
                                <Link to='/services'> {'Services'}</Link>
                                <span class='divider'>/</span>
                            </div>

                        )
                        }

                        {selectedBlog > 0 && (
                            <div className='click' onClick={() => onBreadcrumbClick('/blog')}>
                               <Link to='/blog'>Blog</Link>
                                <span className='divider'>/</span>

                            </div>

                        )}
                        {selectedBlog === 0 && (
                            <div className='click' >
                                {response.caption_en}
                            </div>
                        )}

                        {selectedBlog > 0 && (
                            <div className='click' >
                                {blogToRetrieve.title_en}
                            </div>
                        )}


                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
