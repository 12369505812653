import React, { useEffect, useState } from 'react';


const CopyRights = ({ data, onMatchPath }) => {


    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 250) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const handleScrollToTop = (e) => {
        e.preventDefault(); 
        // Scroll to top smoothly
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        // Set isScrolled to false immediately to hide the button after clicking
        setIsScrolled(false);
      };



    const onHomeClick = () => {
        onMatchPath('/');
    }
    const currentYear = new Date().getFullYear();
    return (
        <div class="copyright">
            <div class="wojo-grid">
                <div class="row align middle">
                    <div class="columns phone-100">©{currentYear} <a href="/" onClick={onHomeClick}>Offensive Logic LLC</a> All Right Reserved.</div>
                    <div class="columns auto phone-100">
                        <a href="https://www.facebook.com/public/Offensive-Logic" target="blank" class="wojo small simple icon button facebook">Facebook</a>
                        <a href="https://twitter.com/offensivelogic" target="blank" class="wojo small simple icon button X">X</a>
                        <a href="https://www.youtube.com/channel/UC3P18ew-YKzzxbscjZkFVlA" target="blank" class="wojo small simple icon button youtube">Youtube</a>
                    </div>
                </div>
            </div>

            {/* <div><a href="#" id="back-to-top"  title="Back to top" ><i class="icon long arrow up"></i></a></div> */}
            <div>
                {/* Use inline style to dynamically set visibility */}
                <a href="" id="back-to-top" title="Back to top" style={{ display: isScrolled ? 'block' : 'none' }} onClick={handleScrollToTop}>
                    <i className="icon long arrow up"></i>
                </a>
            </div>

        </div>
    );
};

export default CopyRights;