// NotFound.js

import React from 'react';
import './NotFound.css'; // Import the updated CSS file
import { Helmet } from 'react-helmet';

const NotFound = () => {
  const apiBaseUrl = process.env.REACT_APP_BASE_API_URL;
  return (
 

    <div>
      <Helmet>
        <title>{'Page not found !!!'}</title>
        <link rel="icon" href={`${apiBaseUrl}/assets/fevicon-logo.png`} type="image/x-icon" />
      </Helmet>


      <div class="container-notfound error-wrapper">
        <div class="404-error-heading">
          <h1 class="section-heading"><span class="red">404</span> Error</h1>
        </div>

        <div class="error-page-content">
          <div class="page-content">
            <h2>Hey there mate! Your lost treasure is not found here...</h2>
            <p>Sorry! The page you are looking for was not found!</p>
            <div class="custom-btn">
              <a class="button btn-wrap" href="/home">HOME</a>
            </div>
          </div>
        </div>

      </div>

    </div>
  );
};

export default NotFound;
