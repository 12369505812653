import React, { useState, useEffect} from 'react';

const Blog = ({ blogData, currentPage, setCurrentPage, onReadMoreClick ,onPaginationeClick }) => {
    // console.log("blogData", blogData);
    if (!blogData) {
        return <p className='loading-blog'></p>; // You can customize the loading state
    }
    const totalPages = 5;
    const handleNextPage = async () => {
        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            onPaginationeClick(currentPage);
            // fetchBlogData(36,currentPage);
        }
    };
    const handlePrevPage = async () => {
        if (currentPage > 1) {
            const prevPage = currentPage - 1;
            setCurrentPage(prevPage);
            // fetchBlogData(36,currentPage);
            onPaginationeClick(currentPage);
        }
    };
    const handleClick = (item) => {
        // console.log("item Id", item);
        onReadMoreClick(item) 
    }
    
    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        onPaginationeClick(pageNumber);
      };

    return (
        <div className='blog'>
            <div className="section blogList" id="IRfwhnl">
                <div className="wojo-grid">
                    <div className="row gutters blogDetail">
                        {blogData.map(blogItem => (
                            <div key={blogItem.id} className="columns">
                                <div className="row big gutters ">
                                    <div className="columns screen-33 tablet-33 mobile-100 phone-100">
                                        <div className="bolgDate">
                                            {/* Date logic can be added here */}
                                            <div className="date-top">{new Date(blogItem.created).getDate()}</div>
                                            <div className="date-bottom">{new Date(blogItem.created).toLocaleString('default', { month: 'short' })}</div>
                                        </div>
                                        <figure className="bottom margin bolgImage">
                                            <a  onClick={() => handleClick(blogItem.id)}>
                                                <img
                                                    className="wojo basic rounded image"
                                                    src={blogItem.thumb}
                                                    alt={blogItem.title_en}
                                                />
                                            </a>
                                            {/* href={blogItem.slug_en} */}
                                        </figure>
                                        <h2 className="bolgTitle">
                                            <a  onClick={() => handleClick(blogItem.id)}>{blogItem.title_en}</a>
                                        </h2>
                                        <p className="wojo mini text bolgBody">{blogItem.description_en}</p>
                                        <br />
                                        <a   className="wojo bolgButton" onClick={() => handleClick(blogItem.id)} >
                                            Read More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Pagination */}
            <div className="pagination">
                <div className='wojo-grid'>
                    <button className='next-prev' onClick={handlePrevPage} disabled={currentPage === 1}>
                        Previous
                    </button>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => handlePageClick(index + 1)}
                            className={currentPage === index + 1 ? 'active' : ''}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button className='next-prev btn-next' onClick={handleNextPage} disabled={currentPage === totalPages}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Blog;
