
import React, { useState, useEffect, useRef } from 'react';

const BlogDetails = ({ responseData, selectedBlog }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [resetFields, setResetFields] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;
    // console.log("Blog Details", selectedBlog);

    useEffect(() => {

    }, [resetFields]);

    useEffect(() => {
        // Load the AddToAny script
        const script = document.createElement('script');
        script.src = 'https://static.addtoany.com/menu/page.js';
        script.async = true;
        document.head.appendChild(script);

        return () => {
            // Cleanup on component unmount
            document.head.removeChild(script);
        };
    }, []);




    // Assuming responseData is passed as a prop
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        website: '',
        body: ''
    });
    // Find the blog with the desired ID (e.g., '1')
    const blogToRetrieve = responseData?.find(blog => blog.id === selectedBlog);
    useEffect(() => {
        const handleFocus = (event) => {
            const parentField = event.target.closest('.field');
            if (parentField) {
                parentField.classList.add('input_focus');

            }
        };

        const handleBlur = (event) => {
            const parentField = event.target.closest('.field');
            if (parentField) {
                if (!event.target.value.trim()) {
                    parentField.classList.remove('input_focus');
                }
            }
        };

        const textarea = document.getElementById('combody');
        textarea.addEventListener('focus', handleFocus);
        textarea.addEventListener('blur', handleBlur);


        const form = document.getElementById('wojo_form');
        const inputFields = form ? form.querySelectorAll('input[type="text"]') : [];
        inputFields.forEach((input) => {
            input.addEventListener('focus', handleFocus);
            input.addEventListener('blur', handleBlur);
        });
        return () => {
            inputFields.forEach((input) => {
                input.removeEventListener('focus', handleFocus);
                input.removeEventListener('blur', handleBlur);
            });
        };
    }, []);

    // Form Submission


    const [fieldErrors, setFieldErrors] = useState({
        firstname: '',
        lastname: '',
        email: '',
        website: '',
        body: ''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    };


    const validateForm = () => {
        const validationErrors = {};

        const requiredFields = ['firstname', 'lastname', 'email', 'website', 'body'];

        requiredFields.forEach((field) => {
            if (!formData[field].trim()) {
                validationErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
            }
        });

        // Add email validation
        if (formData.email.trim()) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(formData.email.trim())) {
                validationErrors.email = 'Invalid email format';
            }
        }

        setFieldErrors(validationErrors);

        return Object.keys(validationErrors).length === 0;
    };


    const message = (message) => {
        const successMessageDiv = document.querySelector('.success-message-comment');
        if (successMessageDiv) {
            successMessageDiv.innerHTML = '';
            const successMessageText = document.createTextNode(message);
            successMessageDiv.appendChild(successMessageText);
            successMessageDiv.style.display = 'block';
            // setTimeout(() => {
            //     successMessageDiv.style.display = 'none';
            // }, 5000);
        }
    }



    const handleSubmit = async () => {
        const isValid = validateForm();
        setIsSubmitting(true);
        if (!isValid) {
            // startProgressBar();
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    model: 'addComment',
                    blogId: selectedBlog,
                    ...formData,
                }),
            });
            const data = await response.json();
            if (data.returnCode === 1) {
                // setIsSubmitting(false);
                console.log('Thank you for your comment. It has been sent:', data.message);
                message('Thank you for your comment. It has been sent');
            } else {
                console.error('Error submitting comment:', data.message);
                message('Error submitting comment');

            }
        } catch (error) {
            console.error('An error occurred while submitting the comment:', error);
            message( 'An error occurred while submitting the comment');
        } finally {
            setIsSubmitting(false);
            setFormData({
                firstname: '',
                lastname: '',
                email: '',
                website: '',
                body: '',
            });
        }
    };

    if (!blogToRetrieve) {
        return <div>No blog found with the specified ID</div>;
    }
    const {
        title_en,
        created,
        user_name,
        body_en,
        comment,
        tags_en,
        thumb,
    } = blogToRetrieve;
    const createMarkup = (html) => ({ __html: html });




    return (
        <div className="blogPedding">
            <div className="wojo-grid">
                <figure className="wojo rounded image margin bottom">
                    <img src={thumb} data-title="" alt={title_en} />
                </figure>
                <h2>{title_en}</h2>
                <div className="wojo horizontal relaxed divided list">
                    <div className="item">
                        <span className="wojo text">Posted Date</span> :{' '}
                        <span className="secondary dashed description">&nbsp;{created}</span>
                    </div>
                    <div className="item">
                        <span className="wojo semi text">Post by</span> :{' '}
                        <span className="secondary dashed description">&nbsp;{user_name}</span>
                    </div>
                    <div className="item">
                        <span className="wojo semi text">Tags</span> :{' '}
                        <span className="secondary dashed description">&nbsp;{tags_en}</span>
                    </div>
                </div>
                {/* Render HTML content safely */}
                <p dangerouslySetInnerHTML={createMarkup(body_en)} />
                <div className="wojo-grid" id="comments">
                    <div className="wojo horizontal relaxed divided list">
                        <div className="item">
                            <span className="wojo semi text secondary">
                                <i className="icon comments"></i>{' '}
                            </span>
                            <span className="wojo semi text">Comment :</span>
                            {/* Check if 'comment' is defined before accessing 'length' */}
                            <span className="dashed description">{comment ? comment.length : 0}</span>
                        </div>
                        <div className="item">

                            <span className="wojo semi text secondary">
                                <a className="a2a_dd" href="https://www.addtoany.com/share">
                                    <i className="icon share" ></i>{' '}
                                </a>
                            </span>
                            <span className="wojo semi text">Share It</span>
                        </div>
                    </div>
                    {comment && comment.length > 0 && (
                        <div className="center aligned margin bottom">
                            <h5>Recent Comments</h5>
                        </div>
                    )}
                    <div className="wojo comments threaded">
                        {/* Check if 'comment' is defined before mapping over it */}
                        {comment && comment.map((c) => (
                            <div key={c.id} className="comment" data-id={c.id} id={`comment_${c.id}`}>
                                <div className="content">
                                    <div className="text">{c.body}</div>-{' '}
                                    <span className="author">{`${c.firstname} ${c.lastname}`}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="full padding center aligned"></div>
                    {/* Add Comment  */}



                    <div class="row big vertical gutters">
                        <div class="columns">
                            <div class="center aligned margin bottom">
                                <h5>Leave A Reply</h5>
                            </div>
                            <div class="wojo form" >
                                <form id="wojo_form" name="wojo_form" method="post">
                                    <div class="wojo fields">
                                        <div class="field">
                                            <label>First Name<em>*</em></label>
                                            <input type="text" name="firstname" value={formData.firstname} onChange={handleChange}  className={` ${fieldErrors.firstname ? 'empty' : ''}`}/>
                                            {fieldErrors.firstname && <p className="error-message">{fieldErrors.firstname}</p>}
                                        </div>
                                        <div class="field">
                                            <label>Last Name<em>*</em></label>
                                            <input type="text" name="lastname" value={formData.lastname} onChange={handleChange}  className={` ${fieldErrors.lastname ? 'empty' : ''}`}/>
                                            {fieldErrors.lastname && <p className="error-message">{fieldErrors.lastname}</p>}
                                        </div>
                                    </div>
                                    <div class="wojo fields">
                                        <div class="field">
                                            <label>Email<em>*</em></label>
                                            <input type="text" name="email" value={formData.email} onChange={handleChange}  className={` ${fieldErrors.email ? 'empty' : ''}`}/>
                                            {fieldErrors.email && <p className="error-message">{fieldErrors.email}</p>}
                                        </div>
                                        <div class="field">
                                            <label>Website<em>*</em></label>
                                            <input type="text" name="website" value={formData.website} onChange={handleChange}  className={` ${fieldErrors.website ? 'empty' : ''}`}/>
                                            {fieldErrors.website && <p className="error-message">{fieldErrors.website}</p>}
                                        </div>
                                    </div>
                                    <div class="wojo fields">
                                        <div class="field message">
                                            <label>Comments<em>*</em></label>
                                            <textarea data-counter="400" class="small" id="combody" name="body" value={formData.body} onChange={handleChange}  className={` ${fieldErrors.body ? 'empty' : ''}`}></textarea>
                                            {fieldErrors.body && <p className="error-message">{fieldErrors.body}</p>}
                                            <p class="wojo tiny text content-right combody_counter">Character Limit <span class="wojo positive text">400 </span></p>
                                        </div>
                                    </div>
                                    <div class="content-center">
                                        <button type="button" data-action="processComment" disabled={isSubmitting} name="dosubmit" class="wojo primary button" onClick={handleSubmit}>Submit Now</button>
                                    </div>
                                    <div className='success-message-comment' hidden>Message</div>
                                    <input name="name" type="hidden" value="" />
                                    <input name="parent_id" type="hidden" value="26" />
                                    <input name="section" type="hidden" value="blog" />
                                    <input name="url" type="hidden" value="/blog/announcing-a-free-plan-for-small-teams/" />
                                </form>
                            </div>
                        </div>
                    </div>

                    {/* END */}
                </div>
            </div>
        </div>
    );
};

export default BlogDetails;
