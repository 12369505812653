// App.js
import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Navigation from './components/Header/Navigation';
import Footer from './components/Footer/Footer';
import Slider from './components/Common/CustomSlider';
import CommonPage from './components/Common/CommonPage';
import Carousel from './components/Common/Carousel';
import Banner from './components/Banner/Banner';
import Blog from './components/Blog/Blog';
import BlogDetails from './components/Blog/BlogDetails';
import NotFound from './components/NotFound/NotFound';
import './App.css';
export const AppContext = React.createContext();

const App = ({ children }) => {
  const [apiData, setApiData] = useState(null);
  const [pageContent, setPageContent] = useState(null);
  const [currentIndexSlider, setCurrentIndexSlider] = useState(0);
  const [sliderData, setSliderData] = useState([]);
  const [carouselData, setCarouselData] = useState([]);

  const [showSlider, setShowSlider] = useState(false);
  const [currentIndexCarousel, setCurrentIndexCarousel] = useState(0);
  const [showCarousel, setShowCarousel] = useState(false);
  const [pageResponse, setPageResponse] = useState(null);
  const [blogPageResponse, setBlogPageResponse] = useState(null);
  const [selectedBlog, setSelectedBlog] = useState(0);
  const [showBlog, setShowBlog] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [menu, setMenu] = useState([]);
  const [menuDataLoaded, setMenuDataLoaded] = useState(false);
  const [activeMenuItemPath, setActiveMenuItemPath] = useState(null);
  const [activeSubMenuPath, setActiveSubMenuPath] = useState(false);
  const [notFoundContent, setNotFoundContent] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;
  console.log("apiUrl : ",apiUrl);;
  // const apiUrl = 'https://offensivelogic.webmasterindia.net/api/';
  const apiBaseUrl = process.env.REACT_APP_BASE_API_URL;
  // console.log("API Url : ",apiUrl);
  // admin-site
  // System Configuration
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(apiUrl, { model: 'systemConfiguration' });
        if (response.data && response.data.returnCode !== 0) {
          setApiData(response.data.data);
        } else {
          console.error('Error or Unexpected Response:', response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [apiData]);

  

  // Menu Item CLick
  const handleMenuItemClick = async (item) => {
    setSelectedBlog(0);
    setActiveMenuItemPath(item.path);
    try {
      const response = await axios.post(apiUrl, { model: 'pageDetail', pageId: item.pageId });
      if (response.data && response.data.returnCode !== 0) {
        const updatedHtmlContent = response.data.data.body_en
          .replace(/\[SITEURL\]/g, apiBaseUrl)
          .replace(//g, '&rdquo;')
          .replace(/–/g, '&ndash;')
          .replace(/—/g, '&mdash;')
          .replace(//g, '&ldquo;')
          .replace(/’/g, '&rsquo;')
          .replace(/|’/g, '&rsquo;')
          .replace(/<div class="([^"]*)clientCertificate([^"]*)">/g, '<div class="section clientCertificate carousel-extra">');
        // console.log("Page Response : ",response.data.data.body_en);
        if (response.data.data.title_en === "Blog") {
          fetchBlogData(36, 1);
          setShowBlog(true);
        } else {
          setShowBlog(false);
        }
        setPageContent(updatedHtmlContent);
        setPageResponse(response.data.data);
        setNotFoundContent(false);
      } else {
        console.error('Error or Unexpected Response:', response.data);
      }
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  // Fetch Blog Data
  const fetchBlogData = async (pageId, pageNo) => {
    try {
      const response = await axios.post(apiUrl, { model: 'blogList', pageId: pageId, pageNo: pageNo });
      if (response.data && response.data.returnCode !== 0) {
        setBlogPageResponse(response.data.data);
      } else {
        console.error('Error or Unexpected Response:', response.data);
        return null;
      }
    } catch (error) {
      console.error('Error fetching blog data:', error);
      return null;
    }
  };

  // Blog Effect
  useEffect(() => {
  }, [pageResponse, pageContent, currentPage, blogPageResponse]);

  const nextSlide = () => {
    setCurrentIndexSlider((prevIndex) => (prevIndex + 1) % sliderData.length);
  };

  const prevSlide = () => {
    setCurrentIndexSlider((prevIndex) => (prevIndex - 1 + sliderData.length) % sliderData.length);
  };

  const nextSlideCarousel = () => {
    // console.log(" Next currentIndexCarousel", currentIndexCarousel);
    setCurrentIndexCarousel((prevIndex) => (prevIndex + 1) % carouselData.length);
  };
  const prevSlideCarousel = () => {
    // console.log(" PREV currentIndexCarousel", currentIndexCarousel);
    setCurrentIndexCarousel((prevIndex) => (prevIndex - 1 + carouselData.length) % carouselData.length);
  };

  // Display Slider and Carousel
  useEffect(() => {
    const uitemDivs = document.querySelectorAll('.uitem');
    const carouselDivs = document.querySelectorAll('.react-carousel img');
    if (pageResponse && pageResponse.slug_en === 'home') {
      if (uitemDivs.length > 0) {
        const newSliderData = Array.from(uitemDivs).map((div) => div.outerHTML);
        setSliderData(newSliderData);
        setShowSlider(true);
      }
      if (carouselDivs.length > 0) {
        const newCarouselData = Array.from(carouselDivs).map((div) => div.outerHTML);
        setCarouselData(newCarouselData);
        setShowCarousel(true);
      }
    }
    if (pageResponse && pageResponse.slug_en === 'clients') {
      if (carouselDivs.length > 0) {
        const newCarouselData = Array.from(carouselDivs).map((div) => div.outerHTML);
        setCarouselData(newCarouselData);
        setShowCarousel(true);
      }
    }
  }, [pageResponse]);

  const updateSliderContent = () => {
    const updatedPageContent = (
      <div className="react-slider">
        <Slider currentIndex={currentIndexSlider} sliderData={sliderData} nextSlide={nextSlide} prevSlide={prevSlide} imagesToShow={1} imagesToScroll={1} />
      </div>
    );
    const reactSliderDiv = document.querySelector('.react-slider');
    if (reactSliderDiv) {
      ReactDOM.render(updatedPageContent, reactSliderDiv);
    }
  };

  const updateCarouselContent = () => {
    const updatedPageContent = (
      <div className="react-carousel">
        <Carousel currentIndex={currentIndexCarousel} sliderData={carouselData} nextSlide={nextSlideCarousel} prevSlide={prevSlideCarousel} imagesToShow={6} imagesToScroll={1} />
      </div>
    );
    const reactCarouselDiv = document.querySelector('.react-carousel');
    if (reactCarouselDiv) {
      ReactDOM.render(updatedPageContent, reactCarouselDiv);
    }
  };

  // Check Path and do Action for carousel
  useEffect(() => {
    if (pageResponse && pageResponse.slug_en === 'home') {
      if (showSlider) {
        updateSliderContent();
      }
      if (showCarousel) {
        updateCarouselContent();
      }
    }
    if (pageResponse && pageResponse.slug_en === 'clients') {
      if (showCarousel) {
        updateCarouselContent();
      }
    }
  }, [showSlider, sliderData, currentIndexSlider, nextSlide, prevSlide, showCarousel, carouselData, currentIndexCarousel, nextSlideCarousel, prevSlideCarousel]);

  // Context Hook for Global
  const contextValue = {
    pageContent,
    setPageContent,
    pageResponse,
    setPageResponse
  };

  const handleReadMoreClick = (blogItem) => {
    setSelectedBlog(blogItem);
    setShowBlog(false);
  };

  const handlePaginationeClick = (currPage) => {
    fetchBlogData(36, currPage);
  };

  // Fetch menu data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(apiUrl, { 'model': 'menuList' });
        if (response.data && response.data.returnCode !== 0) {
          setMenu(response.data.data.menu);
          setMenuDataLoaded(true);
        } else {
          console.error('Error or Unexpected Response:', response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [menu, activeSubMenuPath, carouselData]);

  // Event listener for back and forward or Referesh Page
  useEffect(() => {
    const handlePopstate = () => {
      const currentPath = window.location.pathname;
      // const currentPath = window.location.href;
      console.log("HREF");
      if (menuDataLoaded) {
        // matchPath(currentPath);
        matchPath(!currentPath.includes('/admin-site'));
      } else {
        // console.log("Menu data is still loading...");
      }
    };

    const isPageRefreshed = localStorage.getItem('isPageRefreshed');
    if (isPageRefreshed) {
      // console.log('Page is refreshed');
      if (menuDataLoaded) {
        let currentPath = window.location.pathname;
        if (currentPath === '/about-us/') {
          currentPath = '/about-us';
        }
        matchPath(currentPath);
      }
      localStorage.removeItem('isPageRefreshed');
    }
    localStorage.setItem('isPageRefreshed', true);
    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [menuDataLoaded, pageContent]);

  // match path and Load Data 
  const matchPath = (path) => {
    if (path === '/') {
      path = '/home';
    } else if (path === '/about-us/') {
      path = '/about-us';
    }
    const foundPage = menu.find(item => item.path === path);
    if (!foundPage && menu.some(item => item.submenu)) {
      for (const parentItem of menu) {
        if (parentItem.submenu) {
          const foundSubmenuPage = parentItem.submenu.find(submenuItem => submenuItem.path === path);
          if (foundSubmenuPage) {
            handleMenuItemClick(foundSubmenuPage);
            return;
          }
        }
      }
    }
    if (foundPage) {
      handleMenuItemClick(foundPage);
    } else {
      setNotFoundContent(true);
      // console.log("Page not found in menu");

    }
  };

  // console.log("notFoundContent : ",notFoundContent);

  // Scrolling Stop Direct Load Page when hitt any action
  const targetElementRef = useRef(null);
  useEffect(() => {
    const scrollToContent = () => {
      if (targetElementRef.current) {
        const { top } = targetElementRef.current.getBoundingClientRect();
        window.scrollTo({ top });
      }
    };
    scrollToContent();
  }, [pageContent]);

  // Anchor tag Click event
  const anchorItemClick = async (pageID) => {
    try {
      const response = await axios.post(apiUrl, { model: 'pageDetail', pageId: pageID });
      if (response.data && response.data.returnCode !== 0) {
        const updatedHtmlContent = response.data.data.body_en
          .replace(/\[SITEURL\]/g, apiBaseUrl);
        setPageResponse(response.data.data);
        setPageContent(updatedHtmlContent);
        setNotFoundContent(false);
      } else {
        console.error('Error or Unexpected Response:', response.data);
      }
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };



  useEffect(() => {
    // console.log("Active Sub Menu Path", activeSubMenuPath);
  }, [activeSubMenuPath]);

     // const currentPath = window.location.href;
    //  console.log("HREF",window.location.href);
    //  if()

  return (
    <AppContext.Provider value={contextValue}>{children}
      <Router>
        <Navigation data={apiData} onMenuItemClick={handleMenuItemClick} onMatchPath={matchPath} menu={menu} activeMenuItemPath={activeMenuItemPath} setActiveSubMenuPath={setActiveSubMenuPath} />
        <Routes>
        </Routes>
        {!notFoundContent &&
          <div className='main-app'>
            <div ref={targetElementRef}>
              {pageResponse && pageResponse.banner_en !== null && (
                <Banner setActiveSubMenuPath={setActiveSubMenuPath}  response={pageResponse} onMatchPath={matchPath} activeSubMenuPath={activeSubMenuPath} responseData={blogPageResponse} selectedBlog={selectedBlog} />
              )}
              {pageResponse && pageResponse.title_en === 'Blog' && (
                <div className='blog-page'>
                  {showBlog && (<Blog
                    blogData={blogPageResponse}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    onReadMoreClick={handleReadMoreClick}
                    onPaginationeClick={handlePaginationeClick}
                  />)}
                  {selectedBlog > 0 && <BlogDetails responseData={blogPageResponse} selectedBlog={selectedBlog} />}
                </div>
              )}
              <CommonPage content={pageContent} anchorItemClick={anchorItemClick} setMenuDataLoaded={setMenuDataLoaded} />
            </div>
          </div>
        }
        {notFoundContent &&
          (<NotFound />)}

        <Footer data={apiData} onMenuItemClick={handleMenuItemClick} onMatchPath={matchPath} menu={menu} activeMenuItemPath={activeMenuItemPath} setActiveSubMenuPath={setActiveSubMenuPath} />
      </Router>
    </AppContext.Provider>
  );
};
export default App

